














































import { Vue, Component } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { LoaderComponent } from 'vue-loading-overlay';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ICalendarEvent } from '@/interfaces/crm/calendar-event.interface';
import ContactService from '@/services/crm/contact.service';
import ActivityService from '@/services/crm/activity.service';
import ActivityEventModel from '@/models/crm/activity-event.model';
import { DateHelper } from '@/utils/helpers/date-helper';
import ClientModel from '@/models/crm/client.model';
import dayjs from '@/plugins/dayjs';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import { ObjectHelper } from '@/utils/helpers/object-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import ProcessModel from '@/models/crm/process.model';
import ClientService from '@/services/crm/client.service';
import CrmActivityCalendarEventForm from '@/components/crm/activity-calendar-event-form.vue';
import CrmActivityCalendarEventEmailView from '@/components/crm/activity-calendar-event-email-view.vue';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({
  components: {
    CrmActivityCalendarEventForm,
    CrmActivityCalendarEventEmailView,
  },
})
export default class CrmActivityCalendarEventFormView extends Vue {
  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  userContactInfo!: UserContactInfo;

  process!: ProcessModel;

  startDate = new Date();

  endDate = new Date();

  timed = false;

  model: ActivityEventModel = plainToClass(ActivityEventModel, {
    id: null,
    cnpj: null,
    prospect: null,
    nome: null,
    representante: null,
    titulo: null,
    descricao: null,
    dataHoraInicio: null,
    dataHoraFim: null,
    atendente: null,
    tipoHistorico: null,
    origem: null,
    anexos: [],
    processo: null,
    idRetorno: null,
    flagEnviarEmail: 0,
  });

  startAtTime: string | null = null;

  endAtTime: string | null = null;

  private client: ClientModel | null = null;

  private isRescheduling = false;

  private sendEmail = false;

  private event!: ICalendarEvent;

  async created(): Promise<void> {
    await this.loadUserContactInfo();
    if (this.clientId !== null) {
      await this.loadClient(this.clientId, this.clientType);
    }
    if (this.id !== null) {
      this.loadModel();
      if (this.model) {
        this.process = this.model.processo;
        this.startDate = dayjs(DateHelper.formatToIsoDateString(this.model.dataHoraInicio)).toDate();
        this.endDate = dayjs(DateHelper.formatToIsoDateString(this.model.dataHoraFim)).toDate();
      }
    }
  }

  async mounted(): Promise<void> {
    const loader = this.setBusyLoader();
    try {
      const startDate = dayjs(this.model.dataHoraInicio);
      const endDate = dayjs(this.model.dataHoraFim);

      if (this.model.timed) {
        this.startAtTime = startDate.format('HH:mm');
        this.endAtTime = endDate.format('HH:mm');
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onSave(e: ActivityEventModel): Promise<void> {
    this.model = e;
    if (!this.event) {
      this.event = {
        id: this.model.id,
        name: this.model.titulo,
        start: new Date(this.model.dataHoraInicio.toString().includes(' ')
          ? dayjs(this.model.dataHoraInicio).utc(true).format().toString()
            .substring(0, this.model.dataHoraInicio.toString().lastIndexOf(':') + 3)
          : this.model.dataHoraInicio.toString().substring(
            0, this.model.dataHoraInicio.toString().lastIndexOf(':') + 3,
          )),
        end: new Date(this.model.dataHoraFim.toString().includes(' ')
          ? dayjs(this.model.dataHoraFim).utc(true).format().toString()
            .substring(0, this.model.dataHoraFim.toString().lastIndexOf(':') + 3)
          : this.model.dataHoraFim.toString().substring(0, this.model.dataHoraFim.toString().lastIndexOf(':') + 3)),
        color: '',
        styleName: '',
        timed: false,
        status: this.model.situacao,
        clientType: this.model.tipo,
        cnpj: this.model.cnpj,
        idProspect: Number(this.model.prospect?.codProspect),
        client: '',
        createdBy: undefined,
        process: this.model.processo,
      };
    }
    if (this.model.flagEnviarEmail === 1) {
      this.sendEmail = true;
      if (this.model.cnpj) {
        await this.loadClient(this.model.cnpj, this.model.tipo);
      }
    }
  }

  async onMarkEventAsClose(): Promise<void> {
    try {
      await this.activityService.close(this.model.id as number);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  async onReschedule(): Promise<void> {
    this.isRescheduling = true;
    this.onMarkEventAsClose();
    this.model = plainToClass(ActivityEventModel, {
      id: null,
      cnpj: this.model.cnpj,
      prospect: this.model.prospect,
      nome: this.model.nome,
      representante: this.model.representante,
      titulo: this.model.titulo,
      descricao: this.model.descricao,
      dataHoraInicio: new Date(),
      dataHoraFim: new Date(),
      atendente: this.model.atendente,
      tipoHistorico: this.model.tipoHistorico,
      origem: this.model.origem,
      anexos: this.model.anexos,
      processo: this.model.processo,
      idRetorno: this.model.id,
      flagEnviarEmail: 0,
    });
  }

  get id(): number | null {
    return this.$route.query.id === undefined ? null : Number(this.$route.query.id);
  }

  get cloneId(): number {
    return Number(this.$route.query.cloneId);
  }

  get clientId(): string | null {
    return this.$route.query.clientId === undefined ? null : String(this.$route.query.clientId);
  }

  get clientType(): ClientTypeEnum {
    return this.$route.query.clientType === ClientTypeEnum.Client ? ClientTypeEnum.Client : ClientTypeEnum.Prospect;
  }

  get processId(): number {
    const processIdAux = Number(this.$route.query.processId);
    if (processIdAux !== null) {
      this.process = new ProcessModel();
      this.process.id = processIdAux;
      this.model.processo = this.process;
    }
    return processIdAux;
  }

  get isEditing(): boolean {
    return !!(this.id || this.cloneId);
  }

  private async loadModel(): Promise<void> {
    const result = await this.activityService.getEvent(this.id || this.cloneId);

    let pickList: IKeyValue<string[]> | null = null;

    if (this.cloneId) {
      pickList = {
        pick: [
          'dataHoraInicio',
          'dataHoraFim',
          'timed',
          'anexos',
          'atendente',
          'cnpj',
          'nome',
          'origem',
          'descricao',
          'representante',
          'tipoHistorico',
          'titulo',
          'prospect',
          'tipo',
        ],
      };
    }

    const mappedObject = ObjectHelper.mapObjectValues<ActivityEventModel>(result, pickList);

    this.model = plainToClass(ActivityEventModel, mappedObject);
  }

  async loadUserContactInfo(): Promise<void> {
    try {
      this.userContactInfo = await this.contactService.getLoggedUserContactInfo();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  private async loadClient(clientId: string, clientType: ClientTypeEnum): Promise<void> {
    this.client = await this.clientService.getSummary(clientId, clientType, false);
    if (this.client !== null) {
      this.client.nomeFantasia = this.client.nomeFantasia || this.client.nome;
    }
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.calendarEventFormContainer,
      canCancel: false,
    });
  }
}
